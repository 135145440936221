.profile__info {
  margin: 0;
  padding: 0;
  min-width: 336px;
  position: relative;
  margin-top: 22px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .profile__info {
    margin-right: 0;
    min-width: 282px;
    margin-top: 26px;
    margin-bottom: 36px;
  }
}