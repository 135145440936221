.header {
  max-width: 880px;
  min-height: 74px;
  margin: 0 auto;
  padding-top: 45px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(84,84,84,.7);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .header {
   max-width: 320px;
   min-height: 56px;
   padding-top: 28px;
   margin-bottom: 42px;
  }
}