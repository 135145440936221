.infoTooltip__text {
  margin: 0;
  padding: 0;
  max-width: 358px;
  color: #000;
  text-align: center;
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
}