.popup__submit-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 2px;
  width: 358px;
  height: 50px;
  background-color: #000;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  transition: ease .4s;
  margin-top: 48px;
  margin-bottom: 37px;
}

.popup__submit-button:hover {
  background-color: rgba(0,0,0,.8);
  cursor: pointer;
}

@media (max-width: 767px) {
  .popup__submit-button {
    width: 238px;
    height: 46px;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 25px;
  }
}