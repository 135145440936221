.popup__submit-button_inactive {
  background-color: #fff;
  color: rgba(0,0,0,0.25);
  border: 1px solid rgba(0,0,0,0.25);
}

.popup__submit-button_inactive:hover {
  background-color: rgba(0,0,0,.8);
  cursor: pointer;
  color: #fff;
}