.profile__avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  transition: ease .4s;
  object-fit: cover;
  position: relative;
}

.profile__avatar:hover {
    opacity: .2;
}

@media (max-width: 767px) {
  .profile__avatar {
    margin: 0 auto;
  }
  }