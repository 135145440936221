.popup__input {
  border-style: none;
  min-width: 358px;
  border-bottom: 1px solid rgba(0,0,0,.2);
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 13px;
}

.popup__input:last-of-type {
  margin-top: 30px;
}

.popup__input::placeholder {
  color: #C4C4C4;
}

@media (max-width: 767px) {
  .popup__input {
    min-width: 238px;
  }

  .popup__input:last-of-type {
    margin-bottom: 45px;
  }
}
