.profile__name {
  max-width: 294px;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 42px;
  font-weight: 500;
  line-height: 1.14;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 767px) {
.profile__name {
  max-width: 189px;
  font-size: 27px;
  margin: 0 auto;
  margin-bottom: 14px;
}
}