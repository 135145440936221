.register__text {
  padding: 0;
  margin: 0 auto;
  max-width: 358px;
  text-align: center;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 80px;
}