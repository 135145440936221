.register__input {
  background-color: #000;
  color: #fff;
  border-bottom: 2px solid #ccc;
}

.register__input::placeholder {
  color: #CCC;
}

