.popup__title {
  margin: 0;
  padding: 0;
  max-width: 330px;
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 54px;
  line-height: 23px;
}

@media (max-width: 767px) {
  .popup__title {
    margin-bottom: 75px;
    max-width: 238px;
    font-size: 18px;
  }
}