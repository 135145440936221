.popup__close-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  width: 32px;
  height: 32px;
  background-image: url('../../../images/CloseIcon.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(255,255,255,0);
  position: absolute;
  top: -40px;
  right: -40px;
  transition: ease .4s;
}

.popup__close-button:hover {
  opacity: .6;
  cursor: pointer;
}

@media (max-width: 767px) {
  .popup__close-button {
    width: 20px;
    height: 20px;
    top: -36px;
    right: 0;
  }
}