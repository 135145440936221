.infoTooltip__image {
  max-width: 120px;
  min-height: 120px;
  margin: 0;
  padding: 0;
  margin-top: 60px;
  margin-bottom: 32px;

 /* background-image: url('../../../images/Union.svg');*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  
}