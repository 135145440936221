.popup__container {
  width: 430px;
  border-radius: 10px;
  background-color: #fff;
  padding-top: 34px;
  padding-left: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

@media (max-width: 767px) {
  .popup__container {
    width: 282px;
    padding-top: 25px;
    padding-left: 22px;
  }
}