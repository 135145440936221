.element__delete-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  width: 18px;
  height: 19px;
  background-image: url(../../../images/Trash.svg);
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: ease .4s;
}

.element__delete-button:hover {
  opacity: .5;
  cursor: pointer;
}