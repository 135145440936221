.footer {
  max-width: 880px;
  margin: 0 auto;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .footer {
    max-width: 282px;
    margin-bottom: 36px;
    }
}