.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:  rgba(0,0,0,.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter','Arial', sans-serif;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.8s, opacity 0.3s linear;
}