.profile {
  max-width: 880px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media (max-width: 767px) {
  .profile {
    max-width: 282px;
    flex-direction: column;
  }
}