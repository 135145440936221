.elements {
  max-width: 880px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 67px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; /* не смогла найти в каком браузере контент не центрирован, он везде по центру за счет этого св-ва */
  gap: 20px 17px;
}

@media (max-width: 767px) {
  .elements {
    max-width: 282px;
    gap: 20px;
    margin-top: 36px;
    margin-bottom: 48px;
    }
}
