.profile__avatar-overlay {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-image: url('../../../images/VectorAvatar.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 26px;
  position: relative;
  z-index: 0;
  opacity: 1;
  }